export default class ErpProductAttributeEntity {
    static getEntityData(elem: any) {
        return {
            id: elem.querySelector("#erp_product_attribute_id") ? (elem.querySelector("#erp_product_attribute_id") as HTMLInputElement).value : null,
            uuid: elem.querySelector("#erp_product_attribute_id") ? (elem.querySelector("#erp_product_attribute_id") as HTMLInputElement).value : null,
            name: (elem.querySelector("#erp_product_attribute_name") as HTMLInputElement).value,
            input_type: (elem.querySelector("#erp_product_attribute_input_type option:checked") as HTMLInputElement).value,
            editable: (elem.querySelector("#erp_product_attribute_editable") as HTMLInputElement).checked,
            auto_propagate: (elem.querySelector("#erp_product_attribute_auto_propagate") as HTMLInputElement).checked,
            options: (elem.querySelector("#erp_product_attribute_options") as HTMLInputElement).value.split(",")
        }
    }
}